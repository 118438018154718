export class CustumerHelpers {
  constructor(http, auth) {
    this.http = http;
    this.auth = auth;
  }

  //service client
  async lastBoughtProducts({
    FromProductNumber,
    ToProductNumber,
    LanguageIsoCode,
  }) {
    try {
      const res = await this.http.API_AUTH.$get(
        `order/lastBoughtProducts?Offset=${FromProductNumber}&Limit=${ToProductNumber}&LanguageIsoCode=${LanguageIsoCode}`
      );
      if (res) {
        return res;
      } else {
        return {};
      }
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  async createTicket({
    IdOrder,
    Title,
    Description,
    IdProductAttribute,
    LanguageIsoCode,
  }) {
    try {
      var FormData = require('form-data');
      var formData = new FormData();

      formData.append('Title', Title);
      formData.append('Description', Description);
      formData.append('LanguageIsoCode', LanguageIsoCode);

      if (IdProductAttribute) {
        formData.append('IdProductAttribute', IdProductAttribute);
      }
      if (IdOrder) {
        formData.append('IdOrder', IdOrder);
      }

      const response = await this.http.API_AUTH.post(`ticket`, formData);
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(`Error fetching product: ${error.message}`);
    }
  }

  async listTickets() {
    try {
      const res = await this.http.API_AUTH.$get(`tickets`);
      if (Array.isArray(res)) {
        return res;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  }
  async listMessageTicket({ IdTicket, LanguageIsoCode }) {
    try {
      const res = await this.http.API_AUTH.$get(
        `ticket?IdTicket=${IdTicket}&LanguageIsoCode=${LanguageIsoCode}`
      );
      if (res.IdOrder) {
        return res;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async sendMessage({ IdTicket, Message }) {
    try {
      var FormData = require('form-data');
      var formData = new FormData();

      formData.append('IdTicket', IdTicket);
      formData.append('Message', Message);

      const response = await this.http.API_AUTH.$post(
        `ticket/message`,
        formData
      );
      if (response.status === 200) {
        return response;
      }

      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async rateTicket({ IdTicket, Rating }) {
    var FormData = require('form-data');
    var formData = new FormData();

    formData.append('IdTicket', IdTicket);
    formData.append('Rating', Rating);

    const response = await this.http.API_AUTH.$post(`ticket/rating`, formData);

    return response;
  }

  async autoLogin({ token, email }) {
    try {
      var FormData = require('form-data');
      var formData = new FormData();

      formData.append('Email', email);
      formData.append('Token', token);

      const response = await this.http.API_AUTH.post(
        `customer/autologin`,
        formData
      );
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async createTicketContact({ Firstname, Lastname, Email, Subject, Message }) {
    try {
      var FormData = require('form-data');
      var formData = new FormData();

      formData.append('Firstname', Firstname);
      formData.append('Lastname', Lastname);
      formData.append('Email', Email);
      formData.append('Subject', Subject);
      formData.append('Message', Message);

      const response = await this.http.API.post(`ticket/contact`, formData);
      return response?.data;
    } catch (error) {
      throw new Error(`Error fetching product: ${error.message}`);
    }
  }
}
